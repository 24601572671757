













































































import { Vue, Ref } from "vue-property-decorator";
import Component from "vue-class-component";
import groupModule from "@/store/modules/groups";
import authModule from "@/store/modules/user";
import memberModule from "@/store/modules/members";
import { Group } from "@/types/group";
import { Member } from "@/types/member";
import MemberItem from "@/components/MemberItem.vue";
import { User } from "@/types/user";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";
import Paginate from "@/components/utility/Paginate.vue";
import { GroupStatus } from "@/types/helpers";

@Component({
  components: {
    MemberItem,
    ConfirmModal,
    Paginate,
  },
})
export default class GroupMembers extends Vue {
  @Ref() readonly confirm!: ConfirmModal;

  status = GroupStatus;

  get user(): User | undefined {
    return authModule.user;
  }

  get members(): Member[] {
    return memberModule.accepted;
  }

  get refused(): Member[] {
    return memberModule.refused;
  }

  get group(): Group | undefined {
    return groupModule.group;
  }

  async changeLeader(member: Member): Promise<void> {
    if (!this.group) return;
    const group = JSON.parse(JSON.stringify(this.group)); // quick deep copy
    group.user_id = member.id;
    try {
      await groupModule.updateGroup(group);
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(err.response.data.message);
    }
  }

  async kickMember(member: Member): Promise<void> {
    if (!this.group) return;
    const groupId = this.group.id;
    try {
      await memberModule.removeMember({ groupId, member });
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(err.response.data.message);
    }
  }

  async changeStatus(member: Member, status: number): Promise<void> {
    if (!this.group) return;
    const groupId = this.group.id;
    try {
      await memberModule.changeStatus({ groupId, member, status });
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(err.response.data.message);
    }
  }
}
