var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.group)?_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("group.settings.members"))+" ")])],1),_c('v-card-text',[_c('v-list',{attrs:{"rounded":""}},[_c('paginate',{attrs:{"items":_vm.members,"perPage":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(member){return _c('member-item',{key:member.id,attrs:{"member":member}},[(_vm.user.id == member.id)?_c('v-chip',{staticClass:"text-h7",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t("global.itsme"))+" ")]):(_vm.group.user_id == _vm.user.id)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.changeLeader(member)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("group.members.give-lead.label"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("group.members.give-lead.help", [member.name]))+" ")])]),_c('v-btn',{attrs:{"color":"error","text":"","small":""},on:{"click":function($event){return _vm.kickMember(member)}}},[_vm._v(_vm._s(_vm.$t("group.members.kick")))])],1):_vm._e()],1)})}}],null,false,1138831595)}),(_vm.refused.length > 0)?_c('v-divider'):_vm._e(),(_vm.refused.length > 0)?_c('div',[_c('v-subheader',[_vm._v(_vm._s(_vm.$tc("group.members.rejected", _vm.refused.length)))]),_c('paginate',{attrs:{"items":_vm.refused,"perPage":5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(member){return _c('member-item',{key:member.id,attrs:{"member":member,"isGroupAdmin":_vm.group.user_id == member.id}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.changeStatus(member, _vm.status.ACCEPTED)}}},[_vm._v(_vm._s(_vm.$t("global.accept")))])],1)})}}],null,false,3130345169)})],1):_vm._e()],1)],1),_c('confirm-modal',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }